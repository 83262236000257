// Import all of Bootstrap's CSS

//@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rasa:ital,wght@0,300..700;1,300..700&display=swap');


// $btn-border-radius: 2.5rem;
// $btn-border-radius-lg: 2.5rem;
$font-family-sans-serif: "Inter", Montserrat, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
// $gray-100: #dfe1df;
// $gray-800: #303c42;
// $input-border-radius: 0px;
// // $input-padding-x: 5px;
// // $input-padding-y: 2px;
// // $input-line-height: 1;
// $gray-600: #00b0b3;
// $orange: rgb(255, 163, 0);
// $yellow: #fdb71a;
$blue: #017ACD;
$primary: #017ACD;
//$primary-text: #EBF7FF;
$primary-text: white;
$info: #254E6C;
$info-text: #F0F6FA;
$secondary: #D14905;
$secondary-text: #FFF2EB;
$danger: #B32123;
$danger-text: #FFF2EB;
$warning: #FFCD07;
$warning-text: #FFFBEB;
$success: #457E5C;
$success-text: #F2F8F4;
//#00263e
//$form-check-input-width: 2em;
//rgb(248, 248, 248)
//#3dd8ff
/*
label {
    font-weight: 300;
}*/

// .content-container {
//     min-height: 630px;
// }


@import "node_modules/bootstrap/scss/bootstrap";


.nav-link {
    color: white !important;
}

.pipeline-read,
.pipeline-write {
    cursor: pointer;
}

.pipeline-read:hover,
.pipeline-write:hover {
    background-color: #FCEEEE;
    cursor: pointer;
    color: #B32123;
}

/*
.pipeline-write {
    cursor: pointer;
}

.pipeline-write:hover {
    background-color: #F2F8F4;
    cursor: pointer;
    color: #457E5C;
}
*/
// // html,
// // body,
// // #app,
// // #app>div {
// //     height: 100%
// // }

// input[type=text],
// input[type=email],
// select,
// option {
//     background-color: #f8f8f8 !important;
// }

// /*
// .form-check-label,
// .form-check {
//     font-weight: 300 !important;
//     color: rgb(48, 60, 66);
// }*/

// .menu {
//     background-color: white !important;
//     color: black;
// }

// .bg-dark {
//     /*background-color: #002e6d !important;*/
//     background-color: white !important;

// }

// .btn-primary {
//     color: #fff;
// }

// .btn-lg {
//     text-transform: uppercase;
// }

// // body {
// //     background-color: #ececec;
// // }

// h1,
// h2,
// h3,
// h4,
// h5 {
//     font-family: Rasa;
// }

// h4 {
//     text-transform: uppercase;
// }

// a,
// a:focus,
// button,
// button:focus {
//     outline: none;
// }

.clicker {
    cursor: pointer;
}

.clicker:hover {
    opacity: 0.8;
}

.fancy-title {
    color: #017ACD;
    opacity: 0.7;
    font-variant: small-caps;
    text-align: center;
    font-size: 18px;
}

// .forbidden {
//     opacity: 0.5;
//     background-color: dimgrey;
//     color: linen;
//     cursor: not-allowed;
// }

// .full-square {
//     border: 0;
//     width: 100%;
//     border-radius: 0rem !important;
// }

// .accordion-button {
//     width: 100%;
//     font-size: 1rem !important;
//     align-items: center;
//     border: 0;
//     border-radius: 0;
//     display: flex;
//     overflow-anchor: none;
//     position: relative;
//     text-align: left;
// }

// .brokerVideo {
//     width: 100%;
// }

// /*
// .form-label {
//     font-weight: 600;
// }*/

// .videoWrapper {
//     position: relative;
//     padding-bottom: 56.25%;
//     /* 16:9 */
//     height: 0;
// }

// /* .videoWrapper iframe {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
// } */


// .desktop-menu {
//     background-color: rgb(255, 221, 0) !important;
//     padding-top: 0.05rem !important;
//     padding-bottom: 0.05rem !important;
//     font-size: 1rem !important;
// }

// .phone-button {
//     margin-top: 1rem !important;
//     margin-right: 3rem !important;
// }

// .broker-logo {
//     position: fixed;
//     top: 50px;
//     left: 80px;
//     width: 200px;
//     /*height: 53px;
//     margin-left: 19px;
//     margin-top: 2px;
//     padding-left: 5px;*/
//     /*height: 4.12rem;*/
// }

// .header-big {
//     font-size: 60px;
// }

// .broker-logo-mobile {
//     height: 2rem;
//     margin-top: 1rem;
// }

// .footer-area {
//     background-color: #00263e;
//     color: white;
//     margin-bottom: -20px;
// }

// .footer-secure {
//     color: rgb(78, 195, 224);
//     /*color: rgb(218, 170, 0);*/

// }

// .footer-area a:link {
//     color: white !important;
//     text-decoration: none;
// }

// .footer-link {
//     color: white !important;
//     text-decoration: none;
// }

// .footer-area a:hover {
//     color: rgb(199, 201, 212) !important;
//     text-decoration: none;
// }

// .custom-progress {
//     border-radius: 3rem !important;
// }

// .plan-picker {
//     background-color: rgb(255, 221, 0) !important;
//     color: rgb(0, 38, 62) !important;
// }